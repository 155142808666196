/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useState, useEffect, useRef } from "react"

import { Disclosure, RadioGroup, Tab } from "@headlessui/react"
import { StarIcon } from "@heroicons/react/solid"
import {
  getAssetListingGroups,
  getSingleAssetGroup,
  getSingleAssetRelated,
  getSingleProduct,
  sessToken,
} from "../helpers/DrupalHelper"
import { HeartIcon, MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline"
import Layout from "../components/Layout/Pages"
import { Link, navigate } from "gatsby"
import ProductImageBox from "../components/ProductImageBox"
import MetaDelivery from "../components/Elements/MetaDelivery"
import MetaUpdated from "../components/Elements/MetaUpdated"
import AssetsCard from "../components/Cards/AssestCard"
import RelatedProduct from "../components/RelatedProduct"
import Separator from "../components/Elements/Separator"
import CircleAvatar from "../components/Elements/CircleAvatar"
import Button from "../components/Elements/Button"
import AssetsListingGroups from "../templates/AssetListingGroups"
import ProductAssetListing from "../components/ProductAssetListing"
import Container from "../components/Container"
import { useMediaQuery } from "react-responsive"
import StateContext from "../context/state/StateContext"
import { formatTextInline } from "../helpers/RendererHelper"
import AddToWishlist from "../components/Elements/AddToWishlist"
import { deliveryDate } from "../helpers/DeliveryData"
import MetaDownload from "../components/Elements/MetaDownload"
import { getRemainingTimeToMidnightEST } from "../helpers/ETAEstTime"
import Share from "../components/Elements/Share"
const parse = require("html-react-parser")

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const ProductSingle = ({ location }) => {
  const { token } = useContext(StateContext).state
  const [serverData, setServerData] = useState(null)
  const [rData, setRData] = useState(null)
  const scrollRef = useRef()
  // const productTypes = ["digital", "physical"]
  const goBack = () => {
    // console.log("is back", window.PressedBack)
    window.PressedBack = true
  }
  const latestOrderedDate =
    serverData &&
    serverData?.assets?.filter(
      f => f.status?.ordered_last !== null && f.status?.ordered_last !== ""
    ).length > 0 &&
    serverData?.assets?.reduce((prev, current) => {
      const prevDate = new Date(prev.status?.ordered_last)
      const currentDate = new Date(current.status?.ordered_last)
      return prevDate > currentDate
        ? prev.status?.ordered_last
        : current.status?.ordered_last
    })

  // console.log(latestOrderedDate, "latestOrderedDate")

  // serverData && console.log(serverData)
  useEffect(() => {
    window.addEventListener("popstate", goBack)
    // window.removeEventListener("popstate", () => {
    //   console.log("event removed");
    // });
    return () => {
      window.removeEventListener("popstate", goBack)
    }
  }, [])
  useEffect(async () => {
    let array = location.pathname.split("/")
    if (array.length > 2 && location.pathname !== "/assets/") {
      let nid = array[2]
      const res = await getSingleAssetGroup(token, parseInt(nid))
      if (res) {
        setServerData(res)
      } else {
        navigate("/404")
      }
    } else {
      setServerData({ results: [] })
    }
  }, [])
  useEffect(async () => {
    setServerData(null)
    let array = location.pathname.split("/")
    if (array.length > 2 && location.pathname !== "/assets/") {
      let nid = array[2]
      const res = await getSingleAssetGroup(token, parseInt(nid))
      let relatedData = await getSingleAssetRelated(token, nid)
      setRData(relatedData)
      if (res) {
        setServerData(res)
      } else {
        navigate("/404")
      }
    } else {
      setServerData({ results: [] })
    }
  }, [location])
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  const product = serverData
  // const [selectedColor, setSelectedColor] = useState(product.colors[0])
  if (
    serverData !== null &&
    typeof serverData === "object" &&
    "results" in serverData
  )
    return <AssetsListingGroups serverData={serverData} />
  else {
    let spreadImages = []
    if (serverData?.images) {
      spreadImages = [...serverData.images]
      serverData.assets.map(i => spreadImages.push(...i.cover))
    }
    const productList = []
    serverData &&
      serverData.assets.map((item, index) => productList.push(...item.products))

    return (
      <Layout
        metaTitle={!serverData ? "Asset" : serverData.title}
        metaDesc={""}
      >
        <div className="bg-white">
          {serverData !== null ? (
            <>
              <Container>
                <div className="lg:grid lg:grid-cols-8 lg:gap-x-8 lg:items-start pt-4 md:pt-6">
                  <div className="lg:col-span-4">
                    <ProductImageBox
                      isAsset
                      images={spreadImages}
                      name={serverData.title}
                      category={serverData.category}
                    />
                    <div
                      className="mt-3 p-5 flex flex-col"
                      css={css`
                        background: #f7f7f7;
                        border: 1px solid #ebebeb;
                        border-radius: 8px;
                      `}
                    >
                      {serverData.assets.some(
                        item => item.type === "physical"
                      ) ? (
                        <>
                          <div className={`flex flex-wrap gap-[20px] mb-6`}>
                            <AddToWishlist
                              title={serverData.title}
                              id={serverData.id}
                              name={"asset"}
                              isListed={serverData.bookmarked}
                            />
                            <Share
                              title={serverData.title}
                              id={serverData.id}
                              name={"asset"}
                            />
                          </div>
                          <p>
                            Maximum Order Quantity : Please check each asset
                            below
                          </p>
                          <p>{`Estimated Delivery : ${deliveryDate().toDateString()}. Order within ${getRemainingTimeToMidnightEST().hours
                            } hrs ${getRemainingTimeToMidnightEST().minutes
                            } mins.`}</p>
                        </>
                      ) : (
                        <>
                          <AddToWishlist
                            title={serverData.title}
                            id={serverData.id}
                            name={"asset"}
                            isListed={serverData.bookmarked}
                          />
                          <Share
                            title={serverData.title}
                            id={serverData.id}
                            name={"asset"}
                          />
                        </>
                      )}
                    </div>
                    <div className="mt-[30px] md:mt-7">
                      <div className="flex items-start text-left grid grid-cols-3 mb-4">
                        <h2 className="text-meta-med col-span-1 !leading-[23px]">
                          Last Ordered
                        </h2>
                        <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                          {latestOrderedDate && latestOrderedDate !== ""
                            ? latestOrderedDate
                            : `You haven't previously ordered these assets`}
                        </h2>
                      </div>

                      {serverData.tags && serverData.tags?.length > 0 && (
                        <div className="flex items-start text-left grid grid-cols-3 mb-4">
                          <h2 className="text-meta-med col-span-1 !leading-[23px]">
                            Tags
                          </h2>
                          <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                            {serverData.tags.map((i, k) => (
                              <>
                                {i}
                                {k < serverData.tags.length - 1 && ", "}
                              </>
                            ))}
                          </h2>
                        </div>
                      )}
                      {productList && productList.length > 0 && (
                        <div className="flex items-start text-left grid grid-cols-3 mb-4">
                          <h2 className="text-meta-med col-span-1 !leading-[23px]">
                            Products
                          </h2>
                          <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                            {productList.map((i, k) => (
                              <Link
                                style={{
                                  color: "red",
                                  textDecoration: "underline",
                                }}
                                className="mr-auto"
                                to={`/product/${i.id}`}
                              >
                                <>{`${i.title}${k !== productList.length - 1 ? "," : ""
                                  } `}</>
                              </Link>
                            ))}
                          </h2>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-10 md:px-4 sm:px-0 sm:mt-16 lg:mt-0 lg:col-span-4 lg:pl-8 h-full">
                    <div>
                      <h1
                        className="text-title-med-30 !leading-[30px] md:!leading-[43px] flex flex-col md:flex-row
                items-start md:items-center md:space-x-4 mt-4"
                      >
                        <span>{product.title}</span>
                      </h1>

                      <div className="flex items-center text-left grid grid-cols-3"></div>
                      <div className="flex items-center mt-3 mb-3">
                        <Link
                          to={`/brand/${serverData.brand.id}`}
                          className="mr-auto"
                        >
                          <CircleAvatar
                            image={serverData.brand?.logo || null}
                            text={serverData.brand?.name || ""}
                            alt={serverData.brand?.name || ""}
                            md
                            border
                          />
                        </Link>
                        {serverData.assets.some(
                          item => item.type === "digital"
                        ) &&
                          serverData.assets.some(
                            item => item.type === "physical"
                          ) ? (
                          <div className="flex gap-2">
                            <MetaDelivery large />
                            <MetaDownload large />
                          </div>
                        ) : serverData.assets[0].type === "physical" ? (
                          <MetaDelivery large />
                        ) : (
                          <MetaDownload large />
                        )}
                      </div>

                      {serverData.desc && (
                        <div className="text-reg-16 mt-8 mb-8">
                          {serverData.desc}
                        </div>

                      )}

                      {false && !isMobile && (
                        <>
                          <Separator className="mt-4 mb-4" />
                          {serverData?.assets?.length > 3 && (
                            <div className="mt-3">
                              <h2 className="text-title-semibold-18">
                                Top Assets
                              </h2>
                              <ul role="list" className="mb-7">
                                {serverData.assets
                                  .splice(0, 3)
                                  .map((item, k) => (
                                    <>
                                      <AssetsCard {...item} key={k} mini />
                                      {k !== 2 && (
                                        <Separator className="mt-2 mb-2" />
                                      )}
                                    </>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <Separator className="mt-4 mb-4" />

                    <ProductAssetListing
                      fromAssetGroupPage
                      showTitle={false}
                      assets={serverData.assets}
                      refP={scrollRef?.current}
                      column
                    />
                  </div>
                </div>
              </Container>

              {rData !== null && (
                <div className="bg-sections-alt">
                  {rData.related_brand &&
                    Array.isArray(rData.related_brand) &&
                    rData.related_brand.length > 0 && (
                      <div className="mt-10  bg-[#f7f7f7] pt-10 pb-[5rem]">
                        <Container>
                          <RelatedProduct
                            title={"More from this Brand"}
                            subtitle={`Explore more from ${serverData.brand.name}`}
                            products={rData.related_brand}
                          />
                        </Container>
                      </div>
                    )}
                </div>
              )}
              {rData !== null &&
                rData.related_category &&
                Array.isArray(rData.related_category) &&
                rData.related_category.length > 0 && (
                  <div className="pt-10 pb-[5rem]">
                    <Container>
                      <RelatedProduct
                        title={"More in this Category"}
                        subtitle={`Browse more in ${serverData.category}`}
                        products={rData.related_category}
                      />
                    </Container>
                  </div>
                )}
            </>
          ) : (
            <div className="w-full overflow-hidden flex flex-col items-center justify-center">
              <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

export default ProductSingle